import {
  Flex,
  Heading,
  Icon as PomodoroIcon,
  Text as PomodoroText,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 24px 0 12px 0px;
  width: 100%;
  gap: ${({ theme }) => theme.space.none};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  background: white;
  z-index: 1;
  border-radius: 8px;
`;

export const Title = styled(Heading)`
  margin-top: 0;
  padding: 0px 24px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.m};
`;

export const Option = styled(Flex)`
  gap: ${({ theme }) => theme.space.smaller};
  padding: 10px 24px;
  background-color: white;
  cursor: pointer;

  &:hover {
    filter: brightness(97%);
  }
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const TextLink = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.black};
`;
