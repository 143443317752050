import { useCheckout } from "@app/domains/checkout";
import { Item as CheckoutItem } from "@whitelabel-webapp/checkout/shared/models";
import { Item as CatalogItem } from "@whitelabel-webapp/catalog/shared/models";
import { useCallback, useEffect, useState } from "react";
import { QuickAdd } from "@whitelabel-webapp/shared/design-system";
import { useRouter } from "next/router";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

type QuickAddItemProps = {
  item: CatalogItem;
  quantity?: number;
  variant?: "large" | "small";
  observation?: string;
  enableExtraButtons?: boolean;
};

function getItemInOrder(itemsList: CheckoutItem[], itemId: string) {
  return itemsList.find((item) => item.catalogItem.id == itemId);
}

function getCheckoutItem(
  itemsRecord: Record<string, CheckoutItem>,
  item?: CatalogItem,
) {
  return item && item.instanceId ? itemsRecord[item.instanceId] : undefined;
}

export const QuickAddItem: React.VFC<QuickAddItemProps> = ({
  item,
  quantity,
  variant,
  observation,
  enableExtraButtons,
}) => {
  const { order, addItem, removeItem } = useCheckout();
  const { merchant } = useMerchant();
  const router = useRouter();

  const [currentItem, setItem] = useState(item);
  const [currentQuantity, setQuantity] = useState(quantity);

  useEffect(() => {
    if (quantity) return;
    const itemInOrder = getItemInOrder(order.itemsList, item.id);
    if (itemInOrder) {
      setItem(() =>
        enableExtraButtons
          ? CatalogItem.fromPartial({
              ...itemInOrder.catalogItem,
              productInfo: item.productInfo,
            })
          : itemInOrder.catalogItem,
      );
      setQuantity(itemInOrder.quantity);
    } else {
      setItem(item);
      setQuantity(0);
    }
  }, [order.itemsList, item]);

  useEffect(() => {
    if (!getItemInOrder(order.itemsList, item.id)) return;
    if (!enableExtraButtons) return;
    updateItemInOrder();
  }, [currentItem.productInfo]);

  useEffect(() => {
    if (!currentItem.instanceId || !observation) return;
    const timeout = setTimeout(updateItemInOrder, 800);
    return () => clearTimeout(timeout);
  }, [observation]);

  const canQuickAdd = currentItem.choices.length === 0 || currentQuantity > 0;

  const updateItemInOrder = useCallback(
    async (newQuantity: number = currentQuantity) => {
      const choices = getCheckoutItem(order.items, currentItem)?.choices || [];
      if (newQuantity > 0) {
        return await addItem(currentItem, newQuantity, choices, observation);
      } else if (currentQuantity > 0) {
        return removeItem(currentItem);
      }
    },
    [
      addItem,
      removeItem,
      currentItem,
      currentQuantity,
      order.items,
      observation,
    ],
  );

  const openItemPage = useCallback(async () => {
    return router.push(`/${merchant.query}/item/${item.code}`);
  }, [router, merchant.query, item.code]);

  const productInfo = currentItem.isWeigthVariable()
    ? currentItem.productInfo
    : undefined;

  if (currentQuantity === undefined) return null;

  return (
    <QuickAdd
      enableExtraButtons={enableExtraButtons}
      description={currentItem.description}
      productInfo={productInfo}
      onChange={canQuickAdd ? updateItemInOrder : openItemPage}
      blocked={!canQuickAdd}
      startValue={currentQuantity}
      variant={variant}
    ></QuickAdd>
  );
};
