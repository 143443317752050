export type DescriptorResponse = {
  name: string;
  description: string;
};

export type BrandResponse = {
  id: string;
  image: string;
  name: string;
  description: string;
};

export type PixResponse = {
  qrCode: string;
};

type AmountResponse = {
  currency: string;
  value: number;
};

export type PaymentMethodResponse = {
  id: string;
  method: DescriptorResponse;
  type: DescriptorResponse;
  brand: BrandResponse;
  amount: AmountResponse;
  pix?: PixResponse;
  ttl?: string;
  credit?: {
    cardNumber: string;
  };
};

export type OrderPaymentMethod = {
  id: string;
  name: string;
  inPerson: boolean;
  liability: string;
  amount: AmountResponse;
  digital?: {
    data: string;
  };
  card: {
    brand: string;
    cardNumber: string;
    cardTokenId: string;
  };
  uniqueId: string;
  expiresAt: string;
  experimentations: {
    "v3.ttl": string;
    "v3.type": string;
  };
};

export class PaymentMethod {
  static fromApi(response: PaymentMethodResponse): PaymentMethod {
    return new PaymentMethod(
      response.id,
      response.method,
      response.type,
      response.brand,
      response.pix?.qrCode,
      response.ttl,
      response.credit?.cardNumber,
    );
  }

  static fromOrderApi(response: OrderPaymentMethod): PaymentMethod {
    const method = {
      name: response.name,
      description: response.name,
    };
    const type = {
      name: response.experimentations["v3.type"],
      description: "",
    };
    const brand = {
      id: "",
      image: "",
      name: response.card?.brand ?? method.name,
      description: "",
    };
    const ttl = response.experimentations["v3.ttl"];
    const cardNumber = response.card?.cardNumber;
    const pixQrCode =
      response.name === "PIX" ? response.digital.data : undefined;
    return new PaymentMethod(
      response.id,
      method,
      type,
      brand,
      pixQrCode,
      ttl,
      cardNumber,
    );
  }

  constructor(
    public id: string,
    public method: DescriptorResponse,
    public type: DescriptorResponse,
    public brand: BrandResponse,
    public pixQrCode?: string,
    public ttl?: string,
    public cardNumber?: string,
  ) {}

  formatCardNumber() {
    return this.cardNumber.slice(-4);
  }

  hasTTLExpired() {
    const ttl = this.ttl ?? "";

    return Boolean(ttl) && new Date(ttl).getTime() < new Date().getTime();
  }

  isOfflinePayment() {
    return this.type.name === "OFFLINE";
  }

  isOnlinePayment() {
    return this.type.name === "ONLINE";
  }

  isPix() {
    return this.method.name === "PIX";
  }

  isBankDraft() {
    return this.method.name === "BANK_DRAFT";
  }
}
