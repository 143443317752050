import { Merchant } from "@whitelabel-webapp/merchant/shared/models";
import {
  BaseDrawer,
  Text,
  Divider,
  Flex,
} from "@whitelabel-webapp/shared/design-system";
import NextImage from "next/image";
import { Fragment } from "react";

import { Order, useOrder } from "@app/domains/order";
import {
  formatDateToHourMinutesString,
  formatDateToString,
} from "@app/utils/date";

import { PaymentMethod } from "../PaymentMethod";
import * as S from "./styles";

type OrderDetailsDrawerProps = {
  merchant: Merchant;
  order: Order;
};

export const OrderDetailsDrawer: React.FC<OrderDetailsDrawerProps> = ({
  merchant,
  order,
}) => {
  const { openHelpDrawer, closeOrderDetails, openPixDrawer, openFAQDrawer } =
    useOrder();

  return (
    <BaseDrawer
      open={true}
      onClose={closeOrderDetails}
      data-testid="order-details-drawer"
    >
      <S.Header>
        {closeOrderDetails && (
          <S.ReturnButton handleClick={closeOrderDetails} />
        )}
        <S.HeaderTitle>Pedido Nº {order.orderNumber} </S.HeaderTitle>
        <S.HelpButton onClick={openHelpDrawer}>Ajuda</S.HelpButton>
      </S.Header>

      <BaseDrawer.Body>
        <S.Body>
          {order.isWaitingPayment() && (
            <S.StatusCard variant="waitingPayment">
              <Flex>
                <NextImage
                  height={10}
                  width={10}
                  src="/icons/waiting-payment-status.svg"
                />
                <Text ml="smaller" variant="small">
                  Pedido aguardando pagamento
                </Text>
              </Flex>

              <S.PaymentButton
                onClick={() => openPixDrawer(order)}
                variant="tertiary"
              >
                Fazer Pagamento
              </S.PaymentButton>
              <S.PixFAQButton onClick={openFAQDrawer} variant="small">
                Preciso de ajuda sobre o Pix
              </S.PixFAQButton>
            </S.StatusCard>
          )}
          {order.isInProgress() && (
            <S.StatusCard>
              <Flex>
                <NextImage height={10} width={10} src="/icons/radar.svg" />
                <Text ml="smaller" variant="small">
                  Pedido em andamento
                </Text>
              </Flex>
              <Text mt="smallest" variant="small" color="grayDarkest">
                Realizado às {formatDateToHourMinutesString(order.createdAt)} -{" "}
                {formatDateToString(order.createdAt)}
              </Text>
            </S.StatusCard>
          )}
          {order.isCancelled() && (
            <S.StatusCard variant="neutral">
              <Flex>
                <NextImage
                  height={10}
                  width={10}
                  src="/icons/cancelled-status.svg"
                />
                <Text ml="smaller" variant="small">
                  Pedido cancelado
                </Text>
              </Flex>
              <Text mt="smallest" variant="small" color="grayDarkest">
                Cancelado às {formatDateToHourMinutesString(order.updatedAt)} -{" "}
                {formatDateToString(order.updatedAt)}
              </Text>
            </S.StatusCard>
          )}
          {order.isConcluded() && (
            <S.StatusCard variant="neutral">
              <Flex>
                <NextImage
                  height={10}
                  width={10}
                  src="/icons/concluded-status.svg"
                />
                <Text ml="smaller" variant="small">
                  Pedido concluído
                </Text>
              </Flex>
              <Text mt="smallest" variant="small" color="grayDarkest">
                Concluído às {formatDateToHourMinutesString(order.updatedAt)} -{" "}
                {formatDateToString(order.updatedAt)}
              </Text>
            </S.StatusCard>
          )}
          {order.bag &&
            order.bag.getItems().map((item) => (
              <Fragment key={item.id}>
                <S.ItemListContainer>
                  <S.ItemHeaderContainer>
                    <S.ItemNameContainer>
                      <S.ItemQuantity>{item.quantity}</S.ItemQuantity>
                      <S.ItemName>{item.name}</S.ItemName>
                    </S.ItemNameContainer>
                    <S.ItemPrice>{item.totalPrice.format()}</S.ItemPrice>
                  </S.ItemHeaderContainer>

                  {item.subItems?.map((subitem) => (
                    <S.ItemDetails key={subitem.id}>
                      <S.SecondaryItemQuantity>
                        <S.SecondaryItemQuantityNumber>
                          {subitem.quantity}
                        </S.SecondaryItemQuantityNumber>
                      </S.SecondaryItemQuantity>
                      <S.OrderDetailsText>{subitem.name}</S.OrderDetailsText>
                    </S.ItemDetails>
                  ))}

                  {item.notes && (
                    <S.ItemDetails>
                      <S.OrderDetailsText>Obs: {item.notes}</S.OrderDetailsText>
                    </S.ItemDetails>
                  )}
                </S.ItemListContainer>

                <Divider my="regular" />
              </Fragment>
            ))}

          <S.OrderPaymentContainer>
            <S.OrderPaymentText>
              Subtotal{" "}
              <S.OrderPaymentPrice>
                {order.bag.subTotal.format()}
              </S.OrderPaymentPrice>
            </S.OrderPaymentText>

            <S.OrderPaymentText>
              Taxa de entrega
              <S.OrderPaymentPrice>
                {order.bag.deliveryFee.getValue() > 0
                  ? order.bag.deliveryFee.format()
                  : "-"}
              </S.OrderPaymentPrice>
            </S.OrderPaymentText>

            {order.bag.discount.getValue() !== 0 && (
              <S.OrderPaymentText>
                Incentivo da loja{" "}
                <S.OrderPaymentPrice>
                  -{order.bag.discount.format()}
                </S.OrderPaymentPrice>
              </S.OrderPaymentText>
            )}

            <S.OrderPaymentTotalPrice>
              Total<Flex>{order.bag.total.format()}</Flex>
            </S.OrderPaymentTotalPrice>
          </S.OrderPaymentContainer>

          <Divider my="regular" />

          <PaymentMethod order={order} />

          <Divider my="regular" />

          <S.AddressContainer>
            {order.isDeliveryOrder() ? (
              <>
                <S.DeliveryAddress>Endereço de entrega</S.DeliveryAddress>
                <S.DeliveryAddressText>
                  {order.getNeighborhoodAddress()}
                </S.DeliveryAddressText>
                <S.DeliveryAddressText>
                  {order.getStreetAddress()}
                </S.DeliveryAddressText>
              </>
            ) : (
              <>
                <S.DeliveryAddress>Endereço de retirada</S.DeliveryAddress>
                <S.DeliveryAddressText>
                  {merchant.getNeighborhoodAddress()}
                </S.DeliveryAddressText>
                <S.DeliveryAddressText>
                  {merchant.getStreetAddress()}
                </S.DeliveryAddressText>
              </>
            )}
          </S.AddressContainer>
        </S.Body>
      </BaseDrawer.Body>
    </BaseDrawer>
  );
};
