import { Button, Heading, Text } from "@whitelabel-webapp/shared/design-system";
import SVG from "react-inlinesvg";

import * as S from "./styles";
import { useRouter } from "next/router";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

export const NoOrdersFound: React.VFC = () => {
  const router = useRouter();
  const { merchant } = useMerchant();

  function handleRedirectToCatalog() {
    router.push(`/${merchant.query}`);
  }

  return (
    <S.Container>
      <SVG src="/images/order/empty_state.svg" width={200} height={200} />
      <Heading as="h2" textAlign="center" variant="regular" mt={32} mb={6}>
        Lista de pedidos vazia
      </Heading>
      <Text textAlign="center" variant="regular" color="grayDarkest">
        Seu histórico de pedidos aparecerá aqui.
      </Text>
      <Button size="small" mt="24px" onClick={handleRedirectToCatalog}>
        Começar a comprar
      </Button>
    </S.Container>
  );
};
