import {
  Flex,
  Text,
  Icon as PomodoroIcon,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  width: -webkit-fill-available;
  height: 100%;
  padding: ${({ theme }) => theme.space.large};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: 12px;
`;

export const Container = styled(Flex)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`;

export const TitleContainer = styled(Flex)`
  align-items: center;
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled(Text)`
  margin-left: ${({ theme }) => theme.space.smaller};

  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Date = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
