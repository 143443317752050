import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { Text } from "@whitelabel-webapp/shared/design-system";
import { Money } from "@whitelabel-webapp/shared/models";

import { useCheckout } from "../../../../../context";
import { IndoorLocation } from "./IndoorLocation";
import * as S from "./styles";

export const PriceSummary: React.VFC = () => {
  const { order } = useCheckout();
  const { merchant } = useMerchant();

  const deliveryFee = order.deliveryFee ?? new Money(0);

  const minimumValue = merchant.minimumOrderValue.getValue();
  const subtotalValue = order.totalItems.getValue();
  const isMissingValue = minimumValue > subtotalValue;
  const missingValue = merchant.minimumOrderValue
    .sub(order.totalItems)
    .format();

  const progress = (subtotalValue / minimumValue) * 100;

  return (
    <S.Wrapper>
      <S.Container>
        <Text variant="large" color="black" fontWeight="500">
          Resumo dos valores
        </Text>

        <S.Row aria-label={`Subtotal ${order.totalItems.getValue()} reais`}>
          <Text>Subtotal</Text>
          <Text>{order.totalItems.format()}</Text>
        </S.Row>

        <S.Row aria-label={`Taxa de entrega ${deliveryFee.getValue()} reais`}>
          <Text>Taxa de entrega</Text>
          <Text color={deliveryFee.getValue() == 0 && "#50A773 !important"}>
            {deliveryFee.getValue() !== 0 ? deliveryFee.format() : "Grátis"}
          </Text>
        </S.Row>

        {order.credit.getValue() > 0 && (
          <S.RowDiscount
            aria-label={`Cupom de desconto - ${order.credit.getValue()} reais`}
          >
            <Text>Cupom de desconto</Text>
            <Text> - {order.credit.format()}</Text>
          </S.RowDiscount>
        )}

        <S.Row aria-label={`Total ${order.totalOrder.getValue()} reais`}>
          <Text>
            <b>Total</b>
          </Text>
          <Text>
            <b>{order.totalOrder.format()}</b>
          </Text>
        </S.Row>

        {isMissingValue && (
          <>
            <S.MinimumOrderProgress>
              <S.ProgressBar progress={progress}></S.ProgressBar>
            </S.MinimumOrderProgress>
            <S.MinimumOrderValueDescription>
              Faltam <b>{missingValue}</b> para completar o pedido mínimo
            </S.MinimumOrderValueDescription>
          </>
        )}
      </S.Container>
      {merchant.hasIndoorStoreType() && <IndoorLocation />}
    </S.Wrapper>
  );
};
