import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import React, { useCallback, useMemo, useState } from "react";
import { catalogAboyeur } from "../../aboyeur";
import { byOrder } from "../../utils";
import { Header } from "../Header";

import { Tabs } from "../Tabs";
import { Tab } from "../Tabs/types";
import * as S from "./styles";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { SmallItem } from "@app/domains/item";
import { Carousel } from "@whitelabel-webapp/shared/design-system";

export const List: React.VFC = () => {
  const { merchant } = useMerchant();
  const { catalog } = useCatalog();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabsChange = useCallback((index: number, tabs: Tab[]) => {
    setActiveIndex(index);

    const tabName = String(tabs[index].label);
    catalogAboyeur.events.category.click(tabName);
  }, []);

  const categories = useMemo(
    () =>
      catalog
        .getVisibleCategories()
        .sort((a, b) => b.items.length - a.items.length)
        .slice(0, 20),
    [catalog],
  );

  const mb = { m: "large", _: "largest" };

  return (
    <S.Wrapper>
      <Tabs onChange={handleTabsChange} activeIndex={activeIndex}>
        {categories.map(({ name, items, code }) => {
          const simultaneousItems = 7;
          return (
            <Tabs.Pane key={name} label={name}>
              <Header
                mb={mb}
                name={name}
                route={`/${merchant.query}/categoria/${code}`}
              />
              <Carousel
                gap="clamp(15px, 2.5dvw, 32px)"
                simultaneousItems={simultaneousItems}
              >
                {items.sort(byOrder).map((item) => {
                  return <SmallItem key={item.id} item={item} />;
                })}
              </Carousel>
            </Tabs.Pane>
          );
        })}
      </Tabs>
    </S.Wrapper>
  );
};
