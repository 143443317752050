import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.largest};

  @media (${belowDesktop}) {
    gap: ${({ theme }) => theme.space.regular};
  }
`;

export const Carousel = styled.div`
  display: block;
`;

export const TopGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.larger};
`;

export const BreadcrumbDesktop = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};

  @media (${belowDesktop}) {
    display: none;
    padding: 0 ${({ theme }) => theme.space.regular};
  }

  & a {
    color: ${({ theme }) => theme.colors.grayDarkest};
    font-family: ${({ theme }) => theme.font.family.ifood.body};
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.tsm};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & div {
    color: ${({ theme }) => theme.colors.gray};
  }

  & :last-child {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
  }
`;

export const BreadcrumbMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.regular};

  svg path {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (${belowDesktop}) {
    display: flex;
  }
`;

export const BagButton = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  aspect-ratio: 1;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    color: ${({ theme }) => theme.colors.white};
  }
`;
