import { Flex, Text } from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const desktop = ({ theme }: Themed) => `min-width: ${theme.screens.l.min}`;
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled.div`
  margin-top: auto;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
  padding-top: ${({ theme }) => theme.space.large};
  padding-bottom: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    gap: ${({ theme }) => theme.space.smaller};
    padding-top: ${({ theme }) => theme.space.regular};
    padding-bottom: ${({ theme }) => theme.space.regular};

    > span {
      font-size: ${({ theme }) => theme.fontSizes.s};
    }
  }
`;

export const InvoiceBackgroundImage = styled.div`
  width: 100%;
  height: 43px;
  margin-top: -3px;

  background-image: url("/images/checkout/invoice_bg.png");
  background-repeat: repeat-x;
`;

export const Row = styled(Flex)`
  justify-content: space-between;

  & span {
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grayDarkest};
    font-size: ${({ theme }) => theme.fontSizes.s};

    @media (${belowDesktop}) {
      font-size: ${({ theme }) => theme.fontSizes.tsm};
    }
  }

  &:last-of-type {
    & span {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.black};
    }

    padding-bottom: ${({ theme }) => theme.space.none};
  }
`;

export const RowDiscount = styled(Row)`
  & span {
    color: ${({ theme }) => theme.colors.positive};
  }
`;

export const MinimumOrderValueDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: unset;
  text-align: left;

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.tsm} !important;
  }
`;

export const MinimumOrderProgress = styled.div`
  width: calc(100% - var(--margin) * 2);
  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: 4px;
  height: 8px;
`;

type ProgressBarProps = {
  progress: number;
};

export const ProgressBar = styled.div<ProgressBarProps>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 500ms;
`;
