import { useAccount } from "@whitelabel-webapp/account/shared/account-store";
import { accountAboyeur } from "@whitelabel-webapp/account/shared/config";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import {
  Icon,
  Order,
  PersonOutlined,
  SearchOutlined,
} from "@whitelabel-webapp/shared/design-system";
import { HomeOutlined } from "@ifood/pomodoro-icons";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import * as S from "./styles";
import { useHasOnGoingOrder } from "@app/domains/order/hooks/useHasOnGoingOrder";

function getActiveIndexByPathname(pathname: string) {
  if (pathname === "/[uuid]") return 1;
  if (pathname.endsWith("busca")) return 2;
  if (pathname.endsWith("order")) return 3;
  return 0;
}

export const Menu: React.VFC = () => {
  const router = useRouter();
  const hasOnGoingOrders = useHasOnGoingOrder();

  const { merchant } = useMerchant();
  const { isProfileDrawerOpen, openProfileDrawer, closeProfileDrawer } =
    useAccount();

  useEffect(() => {
    return function unmount() {
      closeProfileDrawer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeIndex, setActiveIndex] = useState(() => {
    const { pathname } = router;
    return getActiveIndexByPathname(pathname);
  });

  useEffect(() => {
    if (isProfileDrawerOpen) return;

    const newActiveIndex = getActiveIndexByPathname(router.pathname);
    setActiveIndex(newActiveIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileDrawerOpen]);

  function handleCatalog() {
    if (activeIndex === 1) return;

    accountAboyeur.events.menu.catalog();

    closeProfileDrawer();
    setActiveIndex(1);
    router.push(`/${merchant.query}`);
  }

  function handleSearch() {
    if (activeIndex === 2) return;

    // criar evento pra quando clicar aqui

    closeProfileDrawer();
    setActiveIndex(2);
    router.push(`/${merchant.query}/busca`);
  }

  function handleOrders() {
    if (activeIndex === 3) return;

    accountAboyeur.events.menu.orders();

    closeProfileDrawer();
    setActiveIndex(3);
    router.push(`/${merchant.query}/order`);
  }

  function handleProfile() {
    if (activeIndex === 4) return;

    accountAboyeur.events.menu.profile();

    setActiveIndex(4);
    openProfileDrawer();
  }

  return (
    <S.Wrapper>
      <S.Container isActive={activeIndex === 1} onClick={handleCatalog}>
        <Icon component={HomeOutlined} size="s" />
        <S.Text>Ínicio</S.Text>
      </S.Container>
      <S.Container isActive={activeIndex === 2}>
        <Icon component={SearchOutlined} size="s" onClick={handleSearch} />
        <S.Text>Busca</S.Text>
      </S.Container>
      <S.Container isActive={activeIndex === 3}>
        <Icon component={Order} size="s" onClick={handleOrders} />
        <S.Text>Pedidos</S.Text>
        {hasOnGoingOrders && <S.OnGoingOrder></S.OnGoingOrder>}
      </S.Container>
      <S.Container isActive={activeIndex === 4}>
        <Icon component={PersonOutlined} size="s" onClick={handleProfile} />
        <S.Text>Perfil</S.Text>
      </S.Container>
    </S.Wrapper>
  );
};
