import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import * as S from "./styles";
import Link from "next/link";
import { ChevronDown } from "@ifood/pomodoro-icons";
import { Icon } from "@ifood/pomodoro-components";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useEffect, useRef, useState } from "react";

export const NavbarCategory: React.VFC = () => {
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const catalogLinks = catalog.links;

  const [slice, setSlice] = useState(10);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateLinksToShow = () => {
      const containerWidth = containerRef.current?.offsetWidth || 0;
      let currentSlice = catalogLinks?.length || 0;
      let linksLength = 0;

      while (currentSlice > 0) {
        linksLength = 0;
        catalogLinks?.slice(0, currentSlice).forEach((link) => {
          linksLength += link.name.length;
        });
        if (linksLength * 12 <= containerWidth) break;
        currentSlice--;
      }

      setSlice(currentSlice);
    };
    calculateLinksToShow();
    window.addEventListener("resize", calculateLinksToShow);
    return () => window.removeEventListener("resize", calculateLinksToShow);
  }, [catalogLinks]);

  return (
    <>
      <S.Container>
        <S.Nav ref={containerRef}>
          <S.AllCategories>
            Todas as categorias
            <Icon component={ChevronDown} width="20px" height="20px"></Icon>
            <S.Dropdown>
              {catalogLinks?.map((link) => (
                <Link
                  key={link.name}
                  href={`/${merchant.query}/categoria/${link.uuid}`}
                >
                  {link.name}
                </Link>
              ))}
            </S.Dropdown>
          </S.AllCategories>
          {catalogLinks?.slice(0, slice).map((link) => (
            <S.NavItem key={link.name}>
              <Link href={`/${merchant.query}/categoria/${link.uuid}`}>
                {link.name}
              </Link>
            </S.NavItem>
          ))}
        </S.Nav>
      </S.Container>
    </>
  );
};
