import { Flex, Icon, Text } from "@ifood/pomodoro-components";
import { ChevronLeft, Share as PomodoroShare } from "@ifood/pomodoro-icons";
import { useAuthentication } from "@whitelabel-webapp/authentication/shared/authentication-store";
import {
  Order as CheckoutOrder,
  UserOpinionSurveyToggleResponse,
} from "@whitelabel-webapp/checkout/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { Loading } from "@whitelabel-webapp/shared/design-system";
import { format, isToday } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";

import { Order } from "@app/domains/order";
import { formatDateToString } from "@app/utils/date";

import { orderAboyeur } from "../../aboyeur";
import { useOrder } from "../../context";
import { useOrdersListQuery } from "../../hooks";
import {
  hasAlreadyAnsweredUserOpinionSurvey,
  setHasAlreadyAnsweredUserOpinionSurveyFlag,
} from "../../utils";
import { FAQList } from "../FAQList";
import { FAQQuestion } from "../FAQQuestion";
import { Help } from "../Help";
import { ItemList } from "../ItemList";
import { NoOrdersFound } from "../NoOrdersFound";
import { NoUserLoggedFound } from "../NoUserLoggedFound";
import { OrderCanceled } from "../OrderCanceled";
import { OrderDetailsDrawer } from "../OrderDetailsDrawer";
import { PixPaymentDrawer } from "../PixPaymentDrawer";
import { UserOpinion } from "../UserOpinion";
import * as S from "./styles";
import Link from "next/link";
import { useRouter } from "next/router";

type OrderListType = {
  orders: Order[];
  date: Date;
};

export function formatDateToOrders(date: Date): string {
  if (isToday(date)) {
    return "Hoje";
  }

  return format(date, "eeeeee. dd MMMM yyyy", { locale: ptBR });
}

export const List: React.VFC = () => {
  const {
    orders,
    pixDrawerStatus,
    closePixDrawer,
    openPixDrawer,
    isHelpDrawerOpen,
    openHelpDrawer,
    closeHelpDrawer,
    selectedOrder,
    FAQDrawerStatus,
    openFAQDrawer,
    closeFAQDrawer,
    openQuestionDrawer,
    closeFAQAndQuestionDrawers,
  } = useOrder();

  const { customer } = useAuthentication();
  const { isLoading } = useOrdersListQuery();
  const { merchant } = useMerchant();
  const router = useRouter();

  const [orderCanceled, setOrderCanceled] = useState<Order | undefined>();

  const [isUserOpinionOpen, setIsUserOpinionOpen] = useState(true);
  const [userOpinionSurvey, setUserOpinionSurvey] = useState<
    UserOpinionSurveyToggleResponse | undefined
  >();

  const ordersByDate = useMemo<OrderListType[]>(() => {
    if (!orders) {
      return [];
    }

    const ordersList = Object.values(
      orders.getOrders().reduce((acc, order) => {
        const orderDate = formatDateToString(order.createdAt);
        if (!acc[orderDate]) {
          return {
            ...acc,
            [orderDate]: {
              date: order.createdAt,
              orders: [order],
            },
          };
        }

        return {
          ...acc,
          [orderDate]: {
            date: order.createdAt,
            orders: [...acc[orderDate].orders, order],
          },
        };
      }, {}),
    ) as OrderListType[];

    const hasOrdersToShow = ordersList.length > 0;

    if (!hasOrdersToShow) {
      const orderStatus = orders.list.map(({ id, lastStatus }) => ({
        id,
        lastStatus,
      }));
      orderAboyeur.events.orderTrackingList.empty(orderStatus);
    } else {
      orderAboyeur.events.orderTrackingList.viewOrderList();
    }

    return ordersList;
  }, [orders]);

  function handleCloseUserOpinion() {
    setIsUserOpinionOpen(false);
  }

  function handleAnswerButtonClick() {
    setIsUserOpinionOpen(false);
    setHasAlreadyAnsweredUserOpinionSurveyFlag();
    orderAboyeur.events.userOpinionSurvey.open(merchant.id);
    window.open(userOpinionSurvey.link, "_blank", "noopener noreferrer");
  }

  function handleNotAnswerButtonClick() {
    setIsUserOpinionOpen(false);
    orderAboyeur.events.userOpinionSurvey.close(merchant.id);
  }

  function handleSuccessOrderAboyeurShareEvents(metadata: string) {
    orderAboyeur.events.share.click(metadata);
  }

  function handleErrorOrderAboyeurShareEvents(error: any) {
    orderAboyeur.events.catch.onError(error);
  }

  useEffect(() => {
    async function fetchUserOpinionSurveyToggle() {
      try {
        const data = await CheckoutOrder.getUserOpinionSurvey();
        setUserOpinionSurvey(data);
      } catch (error: any) {
        orderAboyeur.events.userOpinionSurvey.onError(error);
      }
    }

    fetchUserOpinionSurveyToggle();
  }, []);

  useEffect(() => {
    if (!orders?.list) return;

    const pixOrdersWaitingPayment = orders.list.filter(
      (order) => order.isWaitingPayment() && order.paymentMethod.isPix(),
    );

    if (!pixOrdersWaitingPayment.length) return;

    openPixDrawer(pixOrdersWaitingPayment[0]);
  }, [orders, openPixDrawer]);

  useEffect(() => {
    if (!orders?.list) return;

    const ordersCanceled = orders.list.filter(
      (order) =>
        order.isCancelled() &&
        order.isTodayOrder() &&
        !localStorage.getItem(`${order.id}-order-canceled-view`),
    );

    if (!ordersCanceled.length) return;

    localStorage.setItem(`${ordersCanceled[0].id}-order-canceled-view`, "true");

    setOrderCanceled(ordersCanceled[0]);
  }, [orders, openPixDrawer]);

  const shouldRenderUserOpinionDialog =
    merchant.merchantConfigs.userOpinionSurveyEnabled &&
    userOpinionSurvey?.enabled &&
    !hasAlreadyAnsweredUserOpinionSurvey();

  if (isLoading)
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        paddingTop="large"
        height="100%"
      >
        <Loading variant="large" color="primary">
          Carregando...
        </Loading>
      </Flex>
    );

  return (
    <S.Wrapper>
      <S.BreadcrumbMobile>
        <Icon size="s" component={ChevronLeft} onClick={() => router.back()} />
        <Text>Meus pedidos</Text>
      </S.BreadcrumbMobile>
      {!customer && <NoUserLoggedFound />}
      {customer && !orders?.list.length && <NoOrdersFound />}
      {customer && orders && orders.list.length > 0 && (
        <S.Container>
          <S.Body>
            <Flex width="100%" flexDirection="column" gap="16px">
              <S.Header>
                <S.BreadcrumbDesktop>
                  <Link href={`/${merchant.query}`}>Home</Link>
                  <Flex>{">"}</Flex>
                  <Link href={router.asPath}>Meus pedidos</Link>
                </S.BreadcrumbDesktop>

                <S.CatalogShare
                  campaignLocationName="order"
                  route={`/${merchant.query}`}
                  successAboyeurEvent={handleSuccessOrderAboyeurShareEvents}
                  errorAboyeurEvent={handleErrorOrderAboyeurShareEvents}
                  openAboyeurEvent={() => {
                    orderAboyeur.events.share.openOrderShare();
                  }}
                  closeAboyeurEvent={() => {
                    orderAboyeur.events.share.closeOrderShare();
                  }}
                >
                  <S.TextLink variant="small">
                    <S.Icon component={PomodoroShare} size="xs" />
                    Compartilhe a loja
                  </S.TextLink>
                </S.CatalogShare>
                <S.HelpButton onClick={openHelpDrawer}>
                  Preciso de ajuda
                </S.HelpButton>
              </S.Header>
              <S.Title>Meus pedidos</S.Title>
            </Flex>

            <S.Grid>
              {orders &&
                ordersByDate.map((orderList) => (
                  <>
                    {orderList.orders.map((order) => (
                      <ItemList key={order.id} order={order} />
                    ))}
                  </>
                ))}
            </S.Grid>
          </S.Body>
          {shouldRenderUserOpinionDialog && (
            <UserOpinion
              onNotAnswerButtonClick={handleNotAnswerButtonClick}
              onAnswerButtonClick={handleAnswerButtonClick}
              open={isUserOpinionOpen}
              onClose={handleCloseUserOpinion}
            />
          )}
          {orderCanceled && (
            <OrderCanceled
              order={orderCanceled}
              onClose={() => setOrderCanceled(undefined)}
            />
          )}
        </S.Container>
      )}
      {isHelpDrawerOpen && (
        <Help merchant={merchant} open={true} onClose={closeHelpDrawer} />
      )}

      {pixDrawerStatus.isOpen && (
        <PixPaymentDrawer
          order={pixDrawerStatus.order}
          onClose={closePixDrawer}
          openFAQDrawer={openFAQDrawer}
        />
      )}
      {selectedOrder && (
        <OrderDetailsDrawer merchant={merchant} order={selectedOrder} />
      )}
      {FAQDrawerStatus.isListOpen && (
        <FAQList
          onClose={closeFAQDrawer}
          openQuestionDrawer={openQuestionDrawer}
          closeFAQAndQuestionDrawers={closeFAQAndQuestionDrawers}
        />
      )}
      {FAQDrawerStatus.isQuestionOpen && FAQDrawerStatus.currentQuestion && (
        <FAQQuestion />
      )}
    </S.Wrapper>
  );
};
