import { useCallback } from "react";

import { useCheckout } from "./useCheckout";

export function useResetOrder(): () => void {
  const { updateOrder } = useCheckout();

  const handleResetOrder = useCallback(() => {
    updateOrder((prevOrder) => prevOrder.removeAllItems());
  }, [updateOrder]);

  return handleResetOrder;
}
