import SVG from 'react-inlinesvg';

import * as S from './styles';

export const NoItemsAdded: React.VFC = () => {
  return (
    <S.Wrapper>
      <SVG src="/images/checkout/order_empty.svg" width={200} height={200} />
      <S.Title>Sua sacola está vazia</S.Title>
      <S.Description>
        Encontre os produtos certos para suas compras e adicione a sua sacola.
      </S.Description>
    </S.Wrapper>
  );
};
