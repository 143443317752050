import { Heading as PomodoroHeading } from "@ifood/pomodoro-components";
import {
  Flex,
  Button as PomodoroButton,
  Dialog,
} from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Button = styled(PomodoroButton)`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.s};
  margin-left: ${({ theme }) => theme.space.regular};
  span {
    font-weight: bold;
  }
`;

export const DialogHeader = styled(Dialog.Header)`
  padding-right: ${({ theme }) => theme.space.larger};
`;

export const DialogButton = styled(PomodoroButton)`
  display: block;
  flex: 1;

  font-size: ${({ theme }) => theme.fontSizes.s};
`;

export const Wrapper = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.large};
  margin-bottom: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    margin-top: ${({ theme }) => theme.space.regular};
    margin-bottom: ${({ theme }) => theme.space.regular};
  }
`;

export const Container = styled(Flex)`
  justify-content: space-between;
`;

export const Heading = styled(PomodoroHeading).attrs({
  forwardedAs: "h4",
})`
  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;
