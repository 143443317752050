import { snackbar } from "@ifood/pomodoro-components";
import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import {
  Order as CheckoutOrder,
  OrderCheckoutResponse,
  OrderPayload,
} from "@whitelabel-webapp/checkout/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { DeliveryMethod } from "@whitelabel-webapp/merchant/shared/models";
import { ecommerceEvents } from "@whitelabel-webapp/shared/ecommerce-events";
import { useCallback, useEffect, useState } from "react";

import { ERRORS } from "./constants";
import { useCheckout, useResetOrder } from "./context";

type Options = {
  onError: (err: Error) => void;
  onSuccess: (data: {
    orderResponse: OrderCheckoutResponse;
    orderPayload: OrderPayload;
  }) => void;
};

export function useCreateOrder({ onError, onSuccess }: Options) {
  const { order } = useCheckout();
  const resetOrder = useResetOrder();
  const [loading, setLoading] = useState(false);

  const createOrder = useCallback(
    async function () {
      try {
        const data = await order.sendOrder();
        ecommerceEvents.doCheckout(order, data.orderResponse.id);
        onSuccess(data);
        resetOrder();
      } catch (error: any) {
        onError(error);
      }
    },
    [order, onError, onSuccess, resetOrder],
  );

  const startOrderCreation = useCallback(async () => {
    setLoading(true);

    try {
      const isCheckoutEnabled = await CheckoutOrder.getIsCheckoutEnabled();

      if (!isCheckoutEnabled) {
        throw new Error(ERRORS.CHECKOUT_DISABLED);
      }

      await createOrder();

      setLoading(false);
    } catch (error: any) {
      onError(error);
      setLoading(false);
    }
  }, [createOrder, onError]);

  return { loading, startOrderCreation };
}

export const useSanitizeLocalStorage = () => {
  useEffect(() => {
    Object.entries(localStorage).forEach(([key, value]) => {
      const isUndefined = !value || value === "undefined";
      if (isUndefined) localStorage.removeItem(key);
    });
  }, []);
};

export const useUpdateDeliveryMethod = () => {
  const { merchant } = useMerchant();
  const { order, updateOrder } = useCheckout();
  const {
    deliveryMethod: persistedDeliveryMethod,
    getAddress,
    setDeliveryMethod,
  } = useAddress();

  async function updateDeliveryMethod() {
    try {
      let deliveryMethod: DeliveryMethod;

      if (order.isDefaultDeliveryMethod()) {
        const customerAddress = getAddress("MERCHANT");

        const deliveryMethodResponse = await merchant.getDeliveryMethod(
          customerAddress.coordinates.latitude,
          customerAddress.coordinates.longitude,
        );

        if (!deliveryMethodResponse) return;

        deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
      } else {
        const takeoutMethodResponse = await merchant.getTakeoutMethod();

        if (!takeoutMethodResponse) return;

        deliveryMethod = DeliveryMethod.fromApi(takeoutMethodResponse);
      }

      const selectedTimeSlotId =
        persistedDeliveryMethod.schedule.selectedTimeSlot?.id;

      if (selectedTimeSlotId) {
        const timeSlot = deliveryMethod.schedule.timeSlots.find(
          (timeSlot) => timeSlot.id === selectedTimeSlotId,
        );
        deliveryMethod.schedule.selectedTimeSlot = timeSlot;
      }

      setDeliveryMethod(deliveryMethod);

      updateOrder((prevOrder) => prevOrder.withDeliveryMethod(deliveryMethod));
    } catch (error: any) {
      snackbar({ variant: "error", message: error.message });
      checkoutAboyeur.events.catch.onError(error);
    }
  }

  return { updateDeliveryMethod };
};
