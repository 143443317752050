import { useEffect, useState } from 'react';

import { ChooseDeliveryMethod } from '../ChooseDeliveryMethod';
import { TakeoutAddress } from '../TakeoutAddress';

type Steps = 'CHOOSE' | 'TAKEOUT';

type NoDeliveryMethodProps = {
  open: boolean;
  onClose: () => void;
};

export const NoDeliveryMethod: React.VFC<NoDeliveryMethodProps> = ({ open, onClose }) => {
  const [step, setStep] = useState<Steps>('CHOOSE');

  useEffect(() => {
    if (!open) setStep("CHOOSE");
  }, [open]);

  if (!open) return null;

  return (
    <>
      {step === 'CHOOSE' && <ChooseDeliveryMethod open={true} onTakeout={() => setStep('TAKEOUT')} onClose={onClose} />}
      {step === 'TAKEOUT' && <TakeoutAddress open={true} onClose={onClose} />}
    </>
  );
};
