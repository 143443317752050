import {
  Flex,
  Icon as PomodoroContainer,
  Radio,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.regular};
`;

export const Header = styled(Flex)`
  padding: ${({ theme }) => `${theme.space.regular} ${theme.space.largest}`};
  background-color: ${({ theme }) => theme.colors.grayLight};
  align-items: center;

  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.regular};
  }
`;

export const Quantity = styled.div`
  max-width: 75%;
  & h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const QuantityDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

type TagProps = {
  hasUnsuccessfullyAttemptAddItem: boolean;
};

export const Tag = styled.div<TagProps>`
  padding: 0px 4px 0px 4px;

  background-color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.radii.m};

  animation: ${({ hasUnsuccessfullyAttemptAddItem }) =>
    hasUnsuccessfullyAttemptAddItem ? "shake 3s infinite" : "none"};

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
      background-color: ${({ theme }) => theme.colors.black};
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
      background-color: ${({ theme }) => theme.colors.error};
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
      background-color: ${({ theme }) => theme.colors.error};
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const TagDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  text-transform: uppercase;
`;

export const Icon = styled(PomodoroContainer)`
  color: ${({ theme }) => theme.colors.positive};
`;

export const ItemRadio = styled(Radio.Button)`
  & div > span {
    width: ${({ theme }) => theme.space.large};
    height: ${({ theme }) => theme.space.large};
  }
`;

export const ItemCounter = styled.div`
  width: 30%;
`;
