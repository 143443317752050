/* eslint-disable max-lines */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Header } from "@whitelabel-webapp/account/header";
import { AccountProvider } from "@whitelabel-webapp/account/shared/account-store";
import { AddressProvider } from "@whitelabel-webapp/address/shared/address-store";
import { CustomerAddress } from "@whitelabel-webapp/address/shared/models";
import { AuthenticationProvider } from "@whitelabel-webapp/authentication/shared/authentication-store";
import { CatalogProvider } from "@whitelabel-webapp/catalog/shared/catalog-store";
import { CatalogResponse } from "@whitelabel-webapp/catalog/shared/models";
import { MerchantProvider } from "@whitelabel-webapp/merchant/shared/merchant-store";
import {
  DeliveryMethod,
  MerchantResponse,
} from "@whitelabel-webapp/merchant/shared/models";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";
import { OnHistoryBackProvider } from "@whitelabel-webapp/shared/navigation-utils";
import { Page } from "@whitelabel-webapp/shared/page-wrapper";
import { EmptyProps } from "@whitelabel-webapp/shared/types";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { CatalogFeatureToggles } from "@app/domains/catalog/utils-ssr";
import { useSanitizeLocalStorage } from "@app/domains/checkout/hooks";
import { OnGoingOrderAlert, OrderProvider } from "@app/domains/order";
import { Menu } from "@whitelabel-webapp/account/menu";
import { BagBar } from "@app/domains/checkout/views";

import * as S from "./styles";
import { Loading } from "@ifood/pomodoro-components";
import { Footer } from "../views";
import { Customer } from "@whitelabel-webapp/authentication/shared/models";

const IfoodAuthentication = dynamic<EmptyProps>(() =>
  import("@whitelabel-webapp/authentication/ifood-authentication").then(
    ({ IfoodAuthentication }) => IfoodAuthentication,
  ),
);
const DeliveryAddress = dynamic<EmptyProps>(() =>
  import("@whitelabel-webapp/address/delivery-address").then(
    ({ DeliveryAddress }) => DeliveryAddress,
  ),
);
const Profile = dynamic<EmptyProps>(() =>
  import("@whitelabel-webapp/account/profile").then(({ Profile }) => Profile),
);

const CheckoutProvider = dynamic<EmptyProps>(() =>
  import("@app/domains/checkout").then(
    ({ CheckoutProvider }) => CheckoutProvider,
  ),
);

export type CatalogLayoutProps = {
  featureToggles?: CatalogFeatureToggles;
  merchantResponse?: MerchantResponse;
  catalogResponse?: CatalogResponse;
  serverError?: string;
  merchantPath?: string;
  isCanaryModeEnabled?: boolean;
  initialCustomerAddress?: CustomerAddress;
  initialDeliveryMethod?: DeliveryMethod;
  backgroundColor?: string;
  mockCustomer?: Customer;
  children: any;
  pageName: string;
  pageTitle: string;
};

export const CatalogLayout: React.VFC<CatalogLayoutProps> = ({
  children,
  pageName,
  pageTitle,
  merchantPath,
  merchantResponse,
  catalogResponse,
  initialCustomerAddress,
  initialDeliveryMethod,
  mockCustomer,
  backgroundColor,
  featureToggles = {},
  isCanaryModeEnabled = false,
}) => {
  const {
    isWhatsappOTPToggleEnabled = false,
    isFacebookAuthEnabled = false,
    isGoogleAuthEnabled = false,
    isGoogleRecaptchaEnabled = false,
    onlinePaymentsEnabled = {
      cards: false,
      pix: false,
    },
  } = featureToggles;

  const isMobile = useIsMobile();
  const router = useRouter();
  useSanitizeLocalStorage();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, []);

  const { query } = router;
  const { checkout_details_status } = query;
  const initialCheckoutDetailsStatus = checkout_details_status;

  const merchantDescription = useMemo(
    () =>
      `Faça seu pedido em ${merchantResponse?.name}, delivery de ${merchantResponse
        ?.mainCategory.name} em ${
        merchantResponse?.address.district || ""
      }, ${merchantResponse?.address.city}, ${merchantResponse?.address
        .state} `,
    [merchantResponse],
  );

  return (
    <Page
      name={pageName}
      title={pageTitle}
      merchantResponse={merchantResponse}
      merchantPath={merchantPath}
      description={merchantDescription}
      isCanaryModeEnabled={isCanaryModeEnabled}
      backgroundColor={backgroundColor}
      isGoogleRecaptchaEnabled={isGoogleRecaptchaEnabled}
    >
      <OnHistoryBackProvider>
        <MerchantProvider merchantResponse={merchantResponse}>
          <AuthenticationProvider
            mockCustomer={mockCustomer}
            featureToggles={{
              isFacebookAuthEnabled,
              isGoogleAuthEnabled,
              isWhatsappOTPToggleEnabled,
            }}
          >
            <AccountProvider>
              <AddressProvider merchantResponse={merchantResponse}>
                <CatalogProvider catalogResponse={catalogResponse}>
                  <OrderProvider>
                    <CheckoutProvider
                      initialCustomerAddress={initialCustomerAddress}
                      initialDeliveryMethod={initialDeliveryMethod}
                      initialCheckoutDetailsStatus={
                        initialCheckoutDetailsStatus
                      }
                      onlinePaymentsEnabled={onlinePaymentsEnabled}
                    >
                      <S.FixedHeader>
                        <OnGoingOrderAlert />
                        {!isMobile && <Header showCatalogSearch={true} />}
                      </S.FixedHeader>

                      <S.ContentWrapper>
                        <S.Content>{children}</S.Content>
                      </S.ContentWrapper>

                      <Footer></Footer>

                      <S.BottomFloatingStack>
                        <BagBar />
                        <Menu />
                      </S.BottomFloatingStack>

                      {loading && (
                        <S.LoadingWrapper>
                          <Loading color="primary" variant="large" />
                        </S.LoadingWrapper>
                      )}

                      <DeliveryAddress />
                      <IfoodAuthentication />
                      <Profile />
                    </CheckoutProvider>
                  </OrderProvider>
                </CatalogProvider>
              </AddressProvider>
            </AccountProvider>
          </AuthenticationProvider>
        </MerchantProvider>
      </OnHistoryBackProvider>
    </Page>
  );
};
