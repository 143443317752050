import { List, TextLink } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const ListHeader = styled(List.Header)`
  font-size: ${({ theme }) => theme.fontSizes.m};
`;

export const Close = styled(TextLink)`
  position: absolute;
  top: ${({ theme }) => theme.space.large};
  right: ${({ theme }) => theme.space.regular};

  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
