import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import { useHasOnGoingOrder } from "../../hooks/useHasOnGoingOrder";
import * as S from "./styles";

export const OnGoingOrderAlert: React.VFC = () => {
  const router = useRouter();
  const { merchant } = useMerchant();

  const hasOnGoingOrders = useHasOnGoingOrder();

  const alertIsVisible = useMemo(() => hasOnGoingOrders, [hasOnGoingOrders]);

  const handleClick = useCallback(() => {
    if (hasOnGoingOrders && merchant?.query) {
      router.push(`/${merchant.query}/order`);
    }
  }, [
    hasOnGoingOrders,
    merchant.query,
    merchant.merchantConfigs.isWhitelabelTrackingEnabled,
    router,
  ]);

  return alertIsVisible ? (
    <S.Wrapper onClick={() => handleClick()}>
      <S.Text>Pedido em andamento</S.Text>
      <S.Circle />
      <S.Container>
        <S.LinkText>Clique aqui</S.LinkText> para acompanhar
      </S.Container>
    </S.Wrapper>
  ) : null;
};
