import { useEffect, useState } from "react";
import * as S from "./styles";
import { usePaymentBridge } from "./usePaymentBridge";
import NextImage from "next/image";
import { usePaymentMethods } from "@app/domains/checkout";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";

type AddOnlinePaymentCardProps = {
  onBack: () => void;
  onAddCard: () => void;
};

const PaymentMethodIconsPath = {
  CREDIT: "/icons/payment-method-type/credit.svg",
  DEBIT: "/icons/payment-method-type/debit.svg",
};

export const AddOnlinePaymentCard: React.VFC<AddOnlinePaymentCardProps> = ({
  onBack,
  onAddCard,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const { paymentMethods } = usePaymentMethods();
  const isMobile = useIsMobile();

  const handlePaymentMethodSelection = (methodName: string) => {
    setPaymentMethod(methodName);
  };

  const onlineCards = paymentMethods.getOnlineCardsPayments();
  const allMethods = onlineCards.map((card) => card.method);
  const methods = [
    ...new Map(allMethods.map((method) => [method.name, method])).values(),
  ].filter((method) => method.name !== "BANK_PAY");

  if (methods.length === 1 && !paymentMethod) {
    setPaymentMethod(methods[0].name);
  }

  return (
    <S.Wrapper>
      {!paymentMethod ? (
        <>
          <S.SelectCardToAdd>
            {methods.map((method) => {
              return (
                <button
                  key={method.description}
                  onClick={() => handlePaymentMethodSelection(method.name)}
                >
                  <NextImage
                    src={
                      PaymentMethodIconsPath[method.name] ??
                      "/icons/payment-method-type/credit.svg"
                    }
                    height="20"
                    width="18"
                  />
                  Cartão de {method.description}
                </button>
              );
            })}
          </S.SelectCardToAdd>
          <S.BackButton onClick={onBack}>Voltar</S.BackButton>
        </>
      ) : (
        <>
          <PaymentBridge onAddCard={onAddCard} paymentMethod={paymentMethod} />
          {!isMobile && (
            <S.BackButtonDesktop variant="primary-inverted" onClick={() => setPaymentMethod("")}>
              Voltar
            </S.BackButtonDesktop>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

const PaymentBridge: React.VFC<{
  paymentMethod: string;
  onAddCard: () => void;
}> = ({ paymentMethod, onAddCard }) => {
  const { iframeUrl, cardToken } = usePaymentBridge(paymentMethod);

  useEffect(() => {
    if (!cardToken) return;
    onAddCard();
  }, [cardToken]);

  return (
    <iframe
      id="payment-bridge"
      src={iframeUrl}
      title="Payment Bridge"
      scrolling="no"
    ></iframe>
  );
};
