import tokens from "@ifood/pomodoro-tokens";
import { useListOfRefs } from "@whitelabel-webapp/shared/hooks";
import {
  Children,
  FC,
  ReactNode,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { focusOnFirstButton } from "@app/utils/element";

import { TABS_SIZE } from "./constants";
import useFocusTabOnScroll from "./hooks/useFocusTabOnScroll";
import { Item } from "./Item/Item";
import { Pane } from "./Pane";
import { Slider } from "./Slider";
import * as S from "./styles";
import { Tab, TabsProps } from "./types";
import { tokenToNumber } from "./utils";
import { CatalogSearch } from "modules/account/header/src/CatalogSearch";

type TabsComposition = {
  Pane: typeof Pane;
};

function parseTabs(children: ReactNode): Tab[] {
  return Children.toArray(children)
    .map((node) => {
      if (!isValidElement(node)) {
        return null;
      }

      const key = node.key !== undefined ? String(node.key) : undefined;
      return {
        key,
        ...node.props,
        node,
      };
    })
    .filter(Boolean);
}

function getInitialActiveIndex(props: TabsProps, tabs: Tab[]): number {
  const initialIndex = props?.activeIndex || props?.initialIndex || 0;

  return initialIndex > tabs.length ? tabs.length - 1 : initialIndex;
}

type TabWrapperProps = {
  children: ReactNode;
  sendRef: (ref: HTMLDivElement | null) => void;
};

const TabWrapper: FC<TabWrapperProps> = (props) => {
  const { children, sendRef } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sendRef(ref?.current);
  }, [sendRef, ref]);

  return <section ref={ref}>{children}</section>;
};

const Tabs: FC<TabsProps> & TabsComposition = (props) => {
  const { children, onChange } = props;

  const headerRef = useRef<HTMLDivElement>(null);
  const [tabsRefs, addToTabRefs] = useListOfRefs();
  const [childRefs, addToChildRefs] = useListOfRefs();

  const [slider, setSlider] = useState({ visible: false, left: 0, width: 0 });
  const tabs = useMemo(() => parseTabs(children), [children]);
  const [activeIndex, setActiveIndex] = useState<number>(
    getInitialActiveIndex(props, tabs),
  );

  const scrollToTab = useCallback(
    (index: number): void => {
      const tab = childRefs.current.get(index) as HTMLDivElement;
      if (tab) {
        const topWithOffset =
          tab.offsetTop - TABS_SIZE - tokenToNumber(tokens.space.regular);
        window.scrollTo({ top: topWithOffset, behavior: "smooth" });
      }
    },
    [childRefs],
  );

  // Slider calculation
  useEffect(() => {
    const target = tabsRefs.current.get(activeIndex);
    const container = headerRef.current;

    if (!target || !container) {
      return;
    }

    const cRect = container.getBoundingClientRect();

    if (!cRect || cRect.width === 0) {
      return;
    }

    const tRect = target.getBoundingClientRect();
    const left = tRect.left + container.scrollLeft - cRect.left;
    const width = tRect.width;
    const center = left - width / 2;

    container.scrollLeft = center;
    setSlider({
      visible: true,
      left: left,
      width,
    });
  }, [activeIndex, tabsRefs]);

  const handleIndexChange = (newIndex: number) => (): void => {
    if (newIndex === activeIndex) {
      return;
    }

    onChange?.(newIndex, tabs);
    scrollToTab(newIndex);

    setTimeout(() => {
      setActiveIndex(newIndex);
      focusOnFirstButton(childRefs.current.get(newIndex));
    }, 1000);
  };

  const handleCallback = (nextIndex: number) => {
    setActiveIndex(nextIndex);
  };

  useFocusTabOnScroll({
    activeIndex,
    elements: childRefs,
    callback: handleCallback,
  });

  const [show, setShow] = useState(false);

  const controlTabsHeader = () => {
    if (window.scrollY > 200) {
      if (!show) setShow(true);
    } else if (show) setShow(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlTabsHeader);
    return () => {
      window.removeEventListener("scroll", controlTabsHeader);
    };
  }, [show]);

  const getIdentifier = (index: number) => `tab-${index}`;

  return (
    <div>
      <S.HeaderContainer show={show}>
        <S.SearchContainer show={show}>
          <CatalogSearch />
        </S.SearchContainer>
        <S.Header ref={headerRef}>
          {tabs.map((tab, i) => (
            <Item
              key={tab.key}
              active={i === activeIndex}
              transition={{ duration: 0.25 }}
              onClick={handleIndexChange(i)}
              label={tab.label}
              ref={addToTabRefs(i)}
            >
              {tab.label}
            </Item>
          ))}
          {slider.visible && (
            <Slider
              initial={false}
              style={{
                left: slider.left,
                width: slider.width,
              }}
            />
          )}
        </S.Header>
      </S.HeaderContainer>

      <S.Wrapper>
        {Children.map(children, (child, i) => (
          <TabWrapper key={getIdentifier(i)} sendRef={addToChildRefs(i)}>
            {child}
          </TabWrapper>
        ))}
      </S.Wrapper>
    </div>
  );
};

Tabs.Pane = Pane;

export { Tabs, parseTabs };
