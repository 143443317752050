import { Button, Flex } from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  user-select: none;
  flex: auto;
  position: relative;

  & button {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  iframe {
    border: none;
    height: 810px;
    margin-top: -32px;

    @media (${belowDesktop}) {
      height: 880px;
      margin-top: -30vh;
    }

    @media (max-width: 390px) {
      margin-top: -200px;
    }

    @media (max-width: 360px) {
      margin-top: -140px;
    }
  }
`;

export const SelectCardToAdd = styled(Flex)`
  flex-direction: column;
  height: 100%;
  gap: 16px;

  button {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 24px;
    border: solid 1px ${({ theme }) => theme.colors.grayLight};
    border-radius: 6px;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.font.family.ifood.body};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.grayLight};
    }
  }
`;

export const BackButton = styled(Button)`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
`;

export const BackButtonDesktop = styled(Button)`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  width: 100px;
  position: absolute;
  bottom: 0;
`;
