import { useAuthentication } from "@whitelabel-webapp/authentication/shared/authentication-store";
import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import { Order as CheckoutOrder } from "@whitelabel-webapp/checkout/shared/models";
import { useMerchantAvailability } from "@whitelabel-webapp/merchant/shared/hooks";
import { Z_INDEXES } from "@whitelabel-webapp/shared/constants";
import { snackbar } from "@whitelabel-webapp/shared/design-system";
import { ecommerceEvents } from "@whitelabel-webapp/shared/ecommerce-events";
import { useOnHistoryBack } from "@whitelabel-webapp/shared/navigation-utils";
import { Item as CatalogItem } from "@whitelabel-webapp/catalog/shared/models";
import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";

import { ERRORS } from "../../constants";
import { useCheckout } from "../../context";
import { useCreateOrder, useUpdateDeliveryMethod } from "../../hooks";
import { ConfirmOrder } from "./ConfirmOrder";
import { DeliveryFeeHasChanged } from "./DeliveryFeeHasChanged";
import { DeliveryUnavailable } from "./Status/DeliveryUnavailable";
import { OrderNoted } from "./Status/OrderNoted";
import { PaymentPending } from "./Status/PaymentPending";
import { ProcessingOrder } from "./Status/ProcessingOrder";
import { OrderSummary } from "./Status/OrderSummary";
import { Bag } from "./Status/Bag";
import * as S from "./styles";

type Statuses =
  | "BAG"
  | "ORDER_SUMMARY"
  | "PROCESSING_ORDER"
  | "ORDER_PLACED"
  | "WAITING_PAYMENT"
  | "DELIVERY_UNAVAILABLE";

type CheckoutError = {
  message: string;
};

type CheckoutDetailsProps = {
  open: boolean;
  onClose: () => void;
};

export const CheckoutDetails: React.VFC<CheckoutDetailsProps> = React.memo(
  ({ open, onClose }) => {
    const updateOnHistoryBack = useOnHistoryBack();
    const drawerBodyRef = useRef<HTMLDivElement>();

    const { checkAvailability } = useMerchantAvailability();
    const { openIfoodAuthentication } = useAuthentication();
    const { updateDeliveryMethod } = useUpdateDeliveryMethod();
    const {
      order,
      updateOrder: updateCheckoutOrder,
      updateOrderOnItemChangePrice,
    } = useCheckout();

    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState<Statuses>("BAG");
    const [isConfirmOrderOpen, setIsConfirmOrderOpen] = useState(false);
    const [hasDeliveryFeeChanged, setHasDeliveryFeeChanged] = useState(false);
    const [orderWithDivergentDeliveryFee, setOrderWithDivergentDeliveryFee] =
      useState<CheckoutOrder | undefined>();

    const shouldShowDrawerBack = [
      "BAG",
      "ORDER_SUMMARY",
      "DELIVERY_UNAVAILABLE",
    ].includes(status);

    let title: string = undefined;
    if (status === "BAG") {
      title = "Sacola";
    } else if (status === "ORDER_SUMMARY") {
      title = "Finalize seu pedido";
    } else {
      title = undefined;
    }

    useEffect(() => {
      if (updateOnHistoryBack) {
        updateOnHistoryBack(open ? handleClose : () => void 0);
      }
      if (open) {
        ecommerceEvents.beginCheckout(order);
        checkoutAboyeur.events.details.open();
      }
    }, [open]);

    useEffect(() => {
      if (!order.deliveryMethod) return;
      updateDeliveryMethod();
    }, [order.itemsList.length, order.totalOrder.getValue()]);

    useEffect(() => {
      if (!order.deliveryMethod) return;

      async function fetchDeliveryFee() {
        try {
          const newOrder = await order.sendDeliveryFee();
          updateCheckoutOrder(newOrder);
        } catch (error: any) {
          snackbar({ variant: "error", message: error.message });
          checkoutAboyeur.events.catch.onError(error);
        }
      }

      const timeout = setTimeout(() => {
        fetchDeliveryFee();
      }, 1000);

      return () => clearTimeout(timeout);
    }, [order.deliveryMethod]);

    const handleClose = (runEvent = true) => {
      if (runEvent) checkoutAboyeur.events.details.close();
      setStatus("BAG");
      onClose();
    };

    const handleCloseDeliveryUnavailable = () => {
      handleClose();
    };

    const handleDrawerBack = () => {
      if (status === "DELIVERY_UNAVAILABLE") return setStatus("BAG");
      if (status === "ORDER_SUMMARY") return setStatus("BAG");
      handleClose();
    };

    const openConfirmOrder = async () => {
      try {
        const previousDeliveryFee = order.deliveryFee;
        const newOrder = await order.sendDeliveryFee();
        const { deliveryFee } = newOrder;

        if (previousDeliveryFee.getValue() !== deliveryFee.getValue()) {
          setOrderWithDivergentDeliveryFee(newOrder);
          setHasDeliveryFeeChanged(true);
          return;
        }

        setIsConfirmOrderOpen(true);
      } catch (error: any) {
        snackbar({ variant: "error", message: error.message });
        checkoutAboyeur.events.catch.onError(error);
      }
    };

    const acceptDivergentDeliveryFee = () => {
      updateCheckoutOrder(orderWithDivergentDeliveryFee);
      setIsConfirmOrderOpen(true);
    };

    const concludeBagStep = () => {
      setStatus("ORDER_SUMMARY");
      drawerBodyRef.current.scrollTo({ top: 0, behavior: "smooth" });
    };

    const concludeSummaryStep = async () => {
      setIsLoading(true);
      checkoutAboyeur.events.details.checkoutClick();
      const available = await checkAvailability();
      setIsLoading(false);

      if (!available && !order.deliveryMethod.schedule.selectedTimeSlot) {
        snackbar({ message: "Loja fechada no momento", variant: "error" });
        return;
      }

      if (!order.customer) {
        openIfoodAuthentication("EMAIL", "checkout", {
          callback: () => {
            openConfirmOrder();
          },
        });
      } else {
        openConfirmOrder();
      }
    };

    const onCreateOrderSuccess = ({ orderResponse, orderPayload }) => {
      const {
        name: paymentName,
        type: { name: paymentType },
      } = orderPayload.paymentSources.sources[0].paymentMethod;

      checkoutAboyeur.events.details.purchase(orderPayload.totalOrder, "BRL");
      checkoutAboyeur.events.details.checkoutSuccess(
        orderPayload.totalOrder,
        "BRL",
        orderResponse.id,
        paymentType,
      );

      if (paymentName.toUpperCase() === "PIX") {
        setStatus("WAITING_PAYMENT");
      } else {
        setStatus("ORDER_PLACED");
      }
    };

    const onCreateOrderError = (error: Error) => {
      if (error["catalogItems"]) {
        const catalogItems = error["catalogItems"] as CatalogItem[];
        updateOrderOnItemChangePrice(catalogItems);
      }

      const message = error.message || ERRORS.UNEXPECTED;
      const { response } = error as AxiosError<CheckoutError>;
      const code =
        response?.headers["X-Ifood-Error-Code"] ||
        response?.headers["x-ifood-error-code"] ||
        "";

      checkoutAboyeur.events.details.checkoutError(
        message,
        code,
        order.paymentMethod.type.name,
      );

      if (response?.data?.message === ERRORS.QUOTATION_API) {
        setStatus("DELIVERY_UNAVAILABLE");
      } else {
        snackbar({ variant: "error", message, timeout: 10000 });
        setStatus("ORDER_SUMMARY");
      }
    };

    const { startOrderCreation } = useCreateOrder({
      onSuccess: onCreateOrderSuccess,
      onError: onCreateOrderError,
    });

    const scrollToEmptyField = (elementRef) => {
      const topWithOffSet = elementRef.current?.offsetTop;
      drawerBodyRef.current.scrollTo({
        top: topWithOffSet - 200,
        behavior: "smooth",
      });
    };

    return (
      <S.Wrapper>
        <S.CustomDrawer
          title={title}
          open={open}
          expand={status != "BAG"}
          zIndex={Z_INDEXES.CHECKOUT_DRAWER}
          placement="right"
          onClose={shouldShowDrawerBack ? handleDrawerBack : undefined}
        >
          <S.DrawerBody ref={drawerBodyRef}>
            {status === "BAG" && (
              <Bag handleFinalizeOrderClick={concludeBagStep} />
            )}
            {status === "ORDER_SUMMARY" && (
              <OrderSummary
                handleCheckoutClick={concludeSummaryStep}
                scrollToEmptyField={scrollToEmptyField}
                isLoading={isLoading}
              />
            )}
            {status === "PROCESSING_ORDER" && (
              <ProcessingOrder onProceed={startOrderCreation} />
            )}
            {status === "ORDER_PLACED" && <OrderNoted />}
            {status === "WAITING_PAYMENT" && <PaymentPending />}
            {status === "DELIVERY_UNAVAILABLE" && (
              <DeliveryUnavailable
                onClose={handleCloseDeliveryUnavailable}
                onCloseWithTakeout={() => setStatus("ORDER_SUMMARY")}
              />
            )}
          </S.DrawerBody>
        </S.CustomDrawer>
        {isConfirmOrderOpen && (
          <ConfirmOrder
            onConfirm={() => setStatus("PROCESSING_ORDER")}
            onClose={() => setIsConfirmOrderOpen(false)}
          />
        )}
        {hasDeliveryFeeChanged && (
          <DeliveryFeeHasChanged
            deliveryFee={orderWithDivergentDeliveryFee.deliveryFee}
            onConfirm={acceptDivergentDeliveryFee}
            onClose={() => setHasDeliveryFeeChanged(false)}
          />
        )}
      </S.Wrapper>
    );
  },
);
