import React from "react";

import * as S from "./styles";

type Props = {
  onClick: () => void | Promise<void>;
  isLoading?: boolean;
  isActive?: boolean;
};

const PaymentTypeButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <S.PaymentTypeButton my="smallest" variant="secondary" {...props}>
      {children}
      {props.isActive ? <S.CircleCheck /> : <S.CircleUnchecked />}
    </S.PaymentTypeButton>
  );
};

export default PaymentTypeButton;
