import { Drawer, List } from "@whitelabel-webapp/shared/design-system";

import { FAQ } from "@app/domains/order/constants";

import { Question } from "../../models/";
import { FAQListItem } from "../FAQListItem";
import * as S from "./styles";

interface Props {
  onClose: () => void;
  openQuestionDrawer: (question: Question) => void;
  closeFAQAndQuestionDrawers: () => void;
}

export function FAQList({
  onClose,
  openQuestionDrawer,
  closeFAQAndQuestionDrawers,
}: Props) {
  return (
    <Drawer open title="Ajuda" onClose={onClose}>
      <S.Close onClick={closeFAQAndQuestionDrawers}>Fechar</S.Close>
      <List>
        <S.ListHeader as="h2">Como podemos te ajudar?</S.ListHeader>
        {FAQ.pix.map((question) => (
          <FAQListItem
            key={question.title}
            onClick={() => openQuestionDrawer(question)}
            text={question.title}
          />
        ))}
      </List>
    </Drawer>
  );
}
