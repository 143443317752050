import { Button, Flex } from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  flex-direction: column;
  height: 100%;
`;

export const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smallest};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.s};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.tsm};
  }
`;

export type PaymentTypeButtonProps = {
  isActive?: boolean;
};

export const PaymentTypeButton = styled(Button)<PaymentTypeButtonProps>`
  display: flex;

  gap: ${({ theme }) => theme.space.small};
  padding: ${({ theme }) => theme.space.regular};
  margin: 0;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizes.s};
  text-align: left;

  background-color: ${({ theme }) => theme.colors.white};

  --borderColor: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : theme.colors.grayLight};
  border: 1px solid var(--borderColor);

  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.large};
  }

  & svg {
    color: ${({ theme }) => theme.colors.black};
  }

  &:hover:not(:active):not(:disabled) {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.white : theme.colors.grayLight};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const CircleUnchecked = styled.div`
  width: 15px;
  margin-left: auto;

  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.gray};
`;

export const CircleCheck = styled.div`
  width: 6px;
  margin-left: auto;

  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 6px ${({ theme }) => theme.colors.primary};
`;

export const Tag = styled(Flex)`
  position: absolute;
  top: 7px;
  right: 8px;

  align-items: center;
  justify-content: center;
  width: 42px;
  height: 12px;
  padding: 2px 4px 0px 4px;

  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 7px;

  background-color: ${({ theme }) => theme.colors.grayDarkest};
  border-radius: ${({ theme }) => theme.radii.m};
`;

export const PaymentTypeDescripton = styled.span`
  color: ${({ theme }) => theme.colors.positive};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 12px;
`;

export const BackButton = styled(Button)`
  margin-top: auto;
  font-size: 16px;
  font-weight: 500;
`;
