import {
  Card,
  CardProps,
  Flex,
  FlexProps,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Container = styled(Card) <CardProps>`
  width: 100%;
  gap: 8px;
  padding: ${({ theme }) => theme.space.none};
  margin: ${({ theme }) => theme.space.none};
  display: flex;
  flex-flow: column;
  text-align: left;
  background: none;
  border: none;
  border-radius: ${({ theme }) => theme.space.none};
  appearance: none;
  -webkit-appearance: none;
  border-radius: ${({ theme }) => theme.radii.m};
`;

export const ImageArea = styled(Flex)`
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  cursor: pointer;
`;

export const ImageWrapper = styled(Flex)`
  border: solid 16px white;
  background-color: white;
  filter: brightness(0.97);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;
  width: 100%;

  @media (${belowDesktop}) {
    filter: brightness(0.95);
    border: solid 8px white;
  }

  img {
    width: 100%;
    border-radius: 8px;
    background-color: white;
  }
`;

export const ItemInfo = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.space.smaller};
  width: 100%;
  height: 100%;
`;

export const Description = styled(Text)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: ${({ theme }) => theme.space.none};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.m};
  margin-bottom: ${({ theme }) => theme.space.smallest};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.s};
    margin-bottom: ${({ theme }) => theme.space.none};
    font-weight: 400;
  }
`;

export const LowerSection = styled(Flex)<FlexProps>`
  height: 100%;
  margin-bottom: 15px;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smaller};

  @media (${belowDesktop}) {
    margin-top: ${({ theme }) => theme.space.none};
    margin-bottom: ${({ theme }) => theme.space.none};
    gap: ${({ theme }) => theme.space.smallest};
  }
`;

export const InvertOnMobile = styled(Flex)<FlexProps>`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smaller};
  @media (${belowDesktop}) {
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.space.smallest};
  }
`;

export const PriceSection = styled(Flex)<FlexProps>`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smaller};
  @media (${belowDesktop}) {
    gap: ${({ theme }) => theme.space.smallest};
  }
`;

export const PromotionArea = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};

  @media (${belowDesktop}) {
    gap: ${({ theme }) => theme.space.smallest};
  }
`;

export const Price = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.txl};
  line-height: ${({ theme }) => theme.lineHeights.m};
  font-weight: 500;

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.txl};
    line-height: ${({ theme }) => theme.lineHeights.m};
    font-weight: 500;
    margin-top: ${({ theme }) => theme.space.none};
  }
`;

export const Details = styled(Text)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 16px;

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: 12px;
  }
`;

export const Discount = styled(Text).attrs({
  forwardedAs: "span",
})`
  color: white;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 16px;
  background-color: #27824c;
  width: fit-content;
  padding: 1px 8px;
  gap: 4;
  border-radius: ${({ theme }) => theme.space.regular};

  @media (${belowDesktop}) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const StrikethroughedText = styled(Text)`
  text-decoration-line: line-through;
  line-height: 16px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: 400;
`;

export const PlusIcon = styled(Flex)`
  box-shadow: 0px 2px 5px #00000021;
  border-radius: 50px;
  position: absolute;
  right: 5px;
  bottom: 8px;
`;
