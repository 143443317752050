import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { ActionSheet, Text } from "@whitelabel-webapp/shared/design-system";
import { useEffect } from "react";

import * as S from "./styles";

type NoAddressProps = {
  open: boolean;
  onClose: () => void;
};

export const NoAddress: React.VFC<NoAddressProps> = ({ open, onClose }) => {
  const { openAddress } = useAddress();

  useEffect(() => {
    if (!open) {
      return;
    }
  }, [open]);

  if (!open) return null;

  function handleProvideAddress() {
    onClose();
    openAddress("MERCHANT");
  }

  return (
    <ActionSheet title="Endereço de entrega" open={true} onClose={onClose}>
      <ActionSheet.Body>
        <Text variant="medium" textAlign="center">
          Para prosseguir com o pedido precisamos que você informe seu endereço.
        </Text>
      </ActionSheet.Body>
      <S.Footer>
        <S.Button mt={10} variant="secondary" onClick={onClose}>
          Depois
        </S.Button>
        <S.Button mt={10} variant="primary" onClick={handleProvideAddress}>
          Informar endereço
        </S.Button>
      </S.Footer>
    </ActionSheet>
  );
};
