import { Item } from "@whitelabel-webapp/checkout/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { Text } from "@whitelabel-webapp/shared/design-system";
import NextImage from "next/image";

import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import { QuickAddItem } from "@app/domains/item";
import { useCheckout } from "@app/domains/checkout/context";
import { Sizes, withoutLoader } from "@whitelabel-webapp/shared/image-utils";
import { memo, useCallback, useMemo } from "react";

export const ListItem: React.VFC<{
  item: Item;
}> = memo(({ item }) => {
  const { merchant } = useMerchant();
  const { removeItem } = useCheckout();
  const { catalogItem, choices } = item;

  const handleRemoveItemClick = useCallback(() => {
    removeItem(catalogItem);
  }, [removeItem, catalogItem]);

  const imageSrc = useMemo(
    () => catalogItem.getLogoUrl(Sizes.LOW),
    [catalogItem],
  );

  const isPromotionalItem = useMemo(
    () => catalogItem.isPromotionalItem(),
    [catalogItem],
  );

  const unit = useMemo(() => catalogItem.getFormattedUnit(), [catalogItem]);

  return (
    <S.ListItem>
      <S.Container>
        <S.ImageWrapper>
          <NextImage
            loader={withoutLoader}
            src={imageSrc}
            alt={catalogItem.details}
            layout="fill"
            objectFit="cover"
          />
        </S.ImageWrapper>
        <S.Description>
          <Flex justifyContent="center" flexDirection="column" maxWidth="50%">
            <S.ItemName>
              {item.quantity}x {catalogItem.description}
            </S.ItemName>
            {choices.map((choice) =>
              choice.items.map((garnishItem) => (
                <S.GarnishItem key={garnishItem.id}>
                  <S.GarnishQuantity>{garnishItem.quantity}</S.GarnishQuantity>
                  <S.GarnishName>
                    {garnishItem.catalogGarnishItem.description}
                  </S.GarnishName>
                </S.GarnishItem>
              )),
            )}
            {item.observation.length ? (
              <S.Observation>Obs: {item.observation}</S.Observation>
            ) : null}
          </Flex>
          <Flex justifyContent="center" flexDirection="column" gap="smallest">
            <Flex gap="small" justifyContent="right">
              {isPromotionalItem && (
                <S.StrikethroughedText variant="large" color="grayDarker">
                  {item.calculateOriginalPrice(merchant).format()}
                </S.StrikethroughedText>
              )}
              <S.PriceText color="positive" variant="large">
                {item.calculatePrice(merchant).format()}
              </S.PriceText>
            </Flex>
            <Text
              fontWeight="400"
              color="grayDarkest"
              variant="small"
              textAlign="right"
            >
              {catalogItem.isWeigthVariable()
                ? `${catalogItem.getWeightPrice().format()} / kg`
                : `${catalogItem.getBasePrice().format()} / ${unit}`}
            </Text>
          </Flex>
        </S.Description>
      </S.Container>
      <Flex alignItems="center" justifyContent="space-between">
        <S.RemoveItem
          onClick={handleRemoveItemClick}
          color="grayDarker"
          fontWeight="bold"
        >
          Remover
        </S.RemoveItem>
        <S.EditQuantity>
          <QuickAddItem
            item={catalogItem}
            quantity={item.quantity}
          ></QuickAddItem>
        </S.EditQuantity>
      </Flex>
    </S.ListItem>
  );
});
