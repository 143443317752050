import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { TABS_SIZE } from "../constants";

function useFocusTabOnScroll({
  activeIndex,
  elements,
  callback,
}: {
  activeIndex: number;
  elements: MutableRefObject<Map<number, Element>>;
  callback: (nextIndex: number) => void;
}) {
  const sectionTopOffset = useRef<number>();

  useEffect(() => {
    if (elements.current.size > 0) {
      const topOffset = elements.current.get(0).getBoundingClientRect().top;
      sectionTopOffset.current = topOffset;
    }
  }, [elements]);

  const handleCallback = useCallback(() => {
    for (let index = 0; index < elements.current.size; index++) {
      const element = elements.current.get(index);
      const isInPosition = isElementInPosition(element.getBoundingClientRect());
      if (isInPosition) {
        if (index !== activeIndex) callback(index);
        break;
      }
    }
  }, [activeIndex, elements, callback]);

  useEffect(() => {
    document.addEventListener("scroll", handleCallback);

    return () => {
      document.removeEventListener("scroll", handleCallback);
    };
  }, [handleCallback]);

  const isElementInPosition = (rect: DOMRect) => {
    return rect.top >= TABS_SIZE && rect.bottom <= window.innerHeight - 160;
  };
}

export default useFocusTabOnScroll;
