import { Drawer } from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const desktop = ({ theme }: { theme: DefaultTheme }) =>
  `min-width: ${theme.screens.l.min}`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export type DrawerProps = {
  expand?: boolean;
};

export const CustomDrawer = styled(Drawer)<DrawerProps>`
  @media (${desktop}) {
    transition: max-width 300ms;
    max-width: ${({ expand }) => expand && "100dvw !important"};
    background-color: ${({ expand, theme }) =>
      expand ? theme.colors.grayLight : theme.colors.white} !important;
  }
  opacity: 1 !important;
`;

export const DrawerBody = styled(Drawer.Body)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
