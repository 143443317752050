import { Flex, Text } from "@ifood/pomodoro-components";
import styled, { DefaultTheme } from "styled-components";

const bellowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.l.min}`;

export const Wrapper = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space.regular};
  margin: ${({ theme }) => theme.space.large} 0;

  @media (${bellowDesktop}) {
    margin-top: ${({ theme }) => theme.space.small};
  }
`;

export const Image = styled.div`
  aspect-ratio: 1;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  border: solid 1px ${({ theme }) => theme.colors.grayLight};

  @media (${bellowDesktop}) {
    width: 32px;
    height: 32px;
  }
`;

export const MerchantName = styled(Text)`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;

  @media (${bellowDesktop}) {
    font-size: 16px;
    line-height: 20px;
  }
`;
