import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import { Button, Heading, Text } from "@whitelabel-webapp/shared/design-system";
import { useEffect } from "react";
import * as S from "./styles";

type DeleteCardProps = {
  lastFourDigits?: string;
  onBack: () => void;
  onDeleteCard: () => void;
  onClickCancelButton: () => void;
};

export const DeleteCard: React.FC<DeleteCardProps> = ({
  onBack,
  onClickCancelButton,
  onDeleteCard,
  lastFourDigits,
}) => {
  useEffect(() => {
    checkoutAboyeur.events.payment.deleteCardView();
  }, []);

  return (
    <S.Wrapper>
      <Heading
        as="h4"
        fontSize="larger"
        lineHeight="l"
        m="0"
        textAlign="center"
      >
        Quer remover este cartão?
      </Heading>
      <Text
        maxWidth="400px"
        color="grayDarkest"
        textAlign="center"
        variant="medium"
        mb="regular"
      >
        Ao confirmar, o cartão com final <b>{lastFourDigits}</b> não estará mais
        disponível na sua lista de cartões salvos.
      </Text>
      <S.BottomButtons>
        <Button
          color="primary"
          variant="primary-inverted"
          onClick={onClickCancelButton}
        >
          Voltar
        </Button>
        <Button onClick={onDeleteCard}>Sim, remover cartão</Button>
      </S.BottomButtons>
    </S.Wrapper>
  );
};
