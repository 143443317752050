import { Heading } from "@ifood/pomodoro-components";
import {
  Text,
  Button,
  Flex,
  Share,
} from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const ShareContainer = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.space.large};
`;

export const MerchantItemShare = styled(Share)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
`;

export const StrikethroughedText = styled(Text)`
  text-decoration-line: line-through;
`;

export const Tags = styled(Text)`
  display: inline-flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.space.small};

  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const Circle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.space.large};
  height: ${({ theme }) => theme.space.large};
  margin-right: ${({ theme }) => theme.space.smallest};

  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.l};
`;

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-right: ${({ theme }) => theme.space.smaller};
  padding: ${({ theme }) => theme.space.smallest};

  border: solid 1px ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.m};

  & input {
    width: calc(100% - ${({ theme }) => theme.space.smaller});
  }
`;

export const CartButton = styled(Button)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: ${({ theme }) => `calc(70% - (2 * ${theme.space.large}))`};

  ${Text} {
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    white-space: nowrap;
  }
`;

export const Image = styled.div`
  filter: brightness(0.97);
  border: solid 16px white;
  background-color: white;
  border-radius: 12px;
  flex: auto;
  position: relative;

  img {
    object-fit: contain !important;
  }

  @media (${belowDesktop}) {
    width: 60%;
    aspect-ratio: 1;
    border: none;
    margin: auto;
    filter: brightness(1);
  }
`;

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.largest};

  @media (${belowDesktop}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.space.regular};
  }
`;

export const ItemData = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.larger};
  flex-direction: column;
  flex: auto;
  border: solid 1px ${({ theme }) => theme.colors.gray};
  border-radius: 12px;
  height: fit-content;
  padding: ${({ theme }) => theme.space.largest};
  background-color: white;
  max-width: 500px;

  @media (${belowDesktop}) {
    border: none;
    max-width: inherit;
    width: -webkit-fill-available;
    gap: ${({ theme }) => theme.space.large};
    padding: ${({ theme }) => theme.space.regular};
  }
`;

export const Discount = styled(Text).attrs({
  forwardedAs: "span",
})`
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  background-color: ${({ theme }) => theme.colors.positive};
  width: fit-content;
  padding: 2px 5px;
  border-radius: 6px;
  height: fit-content;
`;

export const ChoicesContainer = styled.div`
  margin: 0 -${({ theme }) => theme.space.largest};

  @media (${belowDesktop}) {
    margin: 0 -${({ theme }) => theme.space.regular};
  }
`;

export const TextDescription = styled(Heading).attrs({
  forwardedAs: "h1",
})`
  margin: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.fontSizes.xl};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.txl};
    line-height: ${({ theme }) => theme.fontSizes.l};
  }
`;

export const TextPrice = styled(Text)`
  margin: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: ${({ theme }) => theme.fontSizes.xl};

  b {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.grayDarkest};
  }

  @media (${belowDesktop}) {
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizes.l};
    line-height: ${({ theme }) => theme.fontSizes.l};
    b {
      font-size: 14px;
    }
  }
`;

export const PriceContainer = styled(Flex)`
  gap: 4px;
  flex-direction: column;
  @media (${belowDesktop}) {
    flex-flow: reverse;
  }
`;
