import { Button, Flex } from "@whitelabel-webapp/shared/design-system";
import NextImage from "next/image";
import styled, { DefaultTheme } from "styled-components";

const desktop = ({ theme }: { theme: DefaultTheme }) =>
  `min-width: ${theme.screens.l.min}`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & button {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
`;

export const Cards = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
  margin-bottom: ${({ theme }) => theme.space.large};
`;

type CardTokenInfoProps = {
  disabled: boolean;
};

export const CardTokenInfo = styled(Flex)<CardTokenInfoProps>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  align-items: center;
  justify-content: space-between;

  padding: 18px;
  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.m};

  & svg,
  & img {
    filter: ${({ disabled }) => (disabled ? "opacity(0.5)" : "opacity(1)")};
  }

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.white : theme.colors.grayLight};
  }

  @media (${desktop}) {
    :first-child {
      margin-top: 0;
    }
  }
`;

export const BrandIcon = styled(NextImage)`
  flex-shrink: 0;
`;

export const CardInfoText = styled(Flex)`
  flex-direction: column;

  text-align: left;
`;

export const Actions = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
`;

export const DeleteCardButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.large};
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const AddNewCardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.largest};

  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${({ theme }) => theme.radii.m};
  cursor: pointer;
`;

export const BottomButtons = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  justify-content: space-between;
  margin-top: auto;

  button {
    font-weight: 500;
  }
`;
