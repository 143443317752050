import styled from "styled-components";
import { maxWidth } from "@app/utils/viewport";

const bellowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.l.min}`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  padding: ${({ theme }) => `0 ${theme.space.loose}`};
  display: flex;
  width: -webkit-fill-available;
  height: 48px;
  position: relative;

  @media (${bellowDesktop}) {
    display: none;
  }
`;

export const Nav = styled.div`
  width: ${maxWidth};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.larger};
  overflow: hidden;
`;

export const NavItem = styled.div`
  font-family: ${({ theme }) => theme.font.family.ifood.body};
  font-size: clamp(${({ theme }) => theme.fontSizes.tsm});
  font-weight: 500;
  text-wrap: nowrap;

  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const AllCategories = styled(NavItem)`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  margin-right: auto;
  align-items: center;
  display: flex;
  height: 100%;

  & svg {
    stroke: white;
    & path {
      stroke-width: 2px;
    }
  }

  & nav {
    display: none;
  }

  &:hover nav {
    display: flex;
  }
`;

export const Dropdown = styled.nav`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  border-top: none;
  border-radius: 0 0px 12px 12px;

  display: flex;
  flex-flow: column;
  gap: 1px;

  overflow: hidden;
  position: absolute;
  top: 48px;

  max-height: calc(calc(13 * 45px) - 1px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray};
    border-radius: 10px;
    border: 5px solid white;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  & a {
    min-width: 248px;
    margin-left: 16px;
    padding: 12px 4px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.black};
    border-bottom: inset 1px ${({ theme }) => theme.colors.grayLight};
  }
`;
