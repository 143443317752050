import { useEffect, useState } from "react";

import { ChevronRight } from "@whitelabel-webapp/shared/design-system";
import { DeliverySlots } from "@app/domains/checkout/views/DeliverySlots";
import { dateStringToDayName } from "@whitelabel-webapp/shared/date-utils";
import { Money } from "@whitelabel-webapp/shared/models";
import { Flex, Text } from "@ifood/pomodoro-components";
import { useCheckout } from "@app/domains/checkout";
import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { accountAboyeur } from "@whitelabel-webapp/account/shared/config";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

import * as S from "./styles";

type DeliveryInfoProps = {
  variant?: "regular" | "small";
};

export const DeliveryInfo: React.VFC<DeliveryInfoProps> = ({
  variant = "regular",
}) => {
  const { order } = useCheckout();
  const { merchant } = useMerchant();
  const { openAddress } = useAddress();
  const [deliveryMethod, setDeliveryMethod] = useState(order.deliveryMethod);
  const [showDeliverySlots, setShowDeliverySlots] = useState(false);
  const hasTimeSlots = Boolean(deliveryMethod?.schedule?.timeSlots?.length);

  useEffect(() => {
    setDeliveryMethod(order.deliveryMethod);
  }, [order.deliveryMethod]);

  let day: string = "";
  let hour: string = "";
  let price: string = "";

  if (deliveryMethod) {
    let timeSlot = deliveryMethod.schedule?.selectedTimeSlot;
    if (!timeSlot && merchant.hasSchedulingFeature()) {
      timeSlot = deliveryMethod.schedule?.timeSlots.at(0);
    }

    if (timeSlot) {
      day = dateStringToDayName(timeSlot.date);
      hour = `${timeSlot.startTime}-${timeSlot.endTime}`;
      price =
        timeSlot.price == 0
          ? "Grátis"
          : new Money(timeSlot.price / 100).format();
    }
  }

  const handleOnClose = () => {
    setShowDeliverySlots(false);
  };

  const handleDeliverySectionClick = () => {
    if (!deliveryMethod) {
      accountAboyeur.events.header.searchForAddress();
      openAddress("MERCHANT");
    } else {
      if (showDeliverySlots || !hasTimeSlots) return;
      setShowDeliverySlots(true);
    }
  };

  const regular = Boolean(variant === "regular");
  const small = Boolean(variant === "small");

  return (
    <>
      <S.DeliverySection onClick={handleDeliverySectionClick}>
        {hasTimeSlots && deliveryMethod && (
          <>
            {regular && (
              <Text fontWeight="500" fontSize="12px" lineHeight="16px">
                {deliveryMethod.isDeliveryMode() && "Entrega"}
                {deliveryMethod.isTakeoutMode() && "Retirada"}
              </Text>
            )}
            {regular && "•"}
            <Text fontWeight="500" fontSize="12px" lineHeight="16px">
              {day},
            </Text>
            <Text fontSize="12px" lineHeight="16px" color="grayDarkest">
              {hour}
            </Text>
            {regular && "•"}
            <Text
              fontSize="12px"
              lineHeight="16px"
              ml={small ? "auto" : "0"}
              fontWeight={price == "Grátis" || small ? "700" : "400"}
              color={price == "Grátis" ? "positive" : "black"}
            >
              {price}
            </Text>
          </>
        )}
        {!hasTimeSlots && deliveryMethod && (
          <>
            {regular && (
              <Text fontWeight="500" fontSize="12px" lineHeight="16px">
                {deliveryMethod.isDeliveryMode() && "Entrega"}
                {deliveryMethod.isTakeoutMode() && "Retirada"}
              </Text>
            )}
            {regular && "•"}
            <Text fontWeight="500" ml="4px" fontSize="12px" lineHeight="16px">
              Indisponível,
            </Text>
            <Text fontSize="12px" lineHeight="16px" color="grayDarkest">
              Sem horários
            </Text>
          </>
        )}
        {!deliveryMethod && (
          <Text fontSize="12px" fontWeight="500" lineHeight="16px">
            Buscar endereço
          </Text>
        )}
        <Flex ml="auto" width="16px" height="16px">
          <ChevronRight color="#A6A6A6" width="100%" height="100%" />
        </Flex>
      </S.DeliverySection>
      <DeliverySlots
        open={showDeliverySlots}
        onClose={handleOnClose}
      ></DeliverySlots>
    </>
  );
};
